form {
  .tooltip {
    position: absolute;
    opacity: 1;

    &.top {
      top: -55px;
    }
  }
}
