@import "./node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss";

$icon-font-path: '../../fonts/bootstrap/';

$gasum-color-black: rgb(0, 0, 0);
$gasum-color-white: rgb(255, 255, 255);

// Graphs
$graph-height: 450px;
$graph-tick-color: rgba(0, 0, 0, 0.1);
$graph-guide-color: rgba(0, 0, 0, 0.3);
$graph-guide-width: 2px;



// *** Colors in "03 visual guidelines.pdf" page 3 "COLORS USED IN DESIGN (EXCL. HOVER ETC.)"
$gasum-color-blue: rgb(100, 145, 245); // #6491f5
$gasum-color-blue-light-1: rgb(130, 167, 247);  // #82a7f7
$gasum-color-blue-light-2: rgb(161, 189, 249);  // #a1bdf9
$gasum-color-blue-light-3: rgb(193, 211, 251);  // #c1d3fb
$gasum-color-blue-light-4: rgb(224, 233, 235);  // #e0e9eb

$gasum-color-brown: rgb(169, 159, 149);         // #a99f95
$gasum-color-brown-light-1: rgb(186, 178, 170); // #bab2aa
$gasum-color-brown-light-2: rgb(203, 197, 191); // #cbc5bf
$gasum-color-brown-light-3: rgb(220, 216, 212); // #dcd8d4
$gasum-color-brown-light-4: rgb(237, 235, 233); // #edebe9

$gasum-color-gray: rgb(241, 242, 242);          // #f1f2f2
$gasum-color-gray-light-1: rgb(107, 107, 107);  // #6b6b6b
$gasum-color-gray-light-2: rgb(143, 143, 143);  // #8f8f8f
$gasum-color-gray-light-3: rgb(180, 180, 180);  // #b4b4b4
$gasum-color-gray-light-4: rgb(217, 217, 217);  // #d9d9d9
$gasum-color-gray-light-5: rgb(247, 247, 247);  // #f7f7f7

$gasum-color-gray-dark: rgb(88, 89, 91);        // #58595b
$gasum-color-gray-dark-1: rgb(67, 67, 68);      // #434344

$gasum-color-green: rgb(100,224,149);           // #64e095
$gasum-color-green-light-1: rgb(130, 230, 170); // #82e6aa
$gasum-color-green-light-2: rgb(161, 236, 190); // #a1ecbe
$gasum-color-green-light-3: rgb(192, 242, 212); // #c0f2d4
$gasum-color-green-light-4: rgb(223, 248, 233); // #dff8e9

$gasum-color-red: rgb(255, 110, 134);           // #ff6e86
$gasum-color-red-light-1: rgb(255, 138, 158);   // #ff8a9e
$gasum-color-red-light-2: rgb(255, 167, 182);   // #ffa7b6
$gasum-color-red-light-3: rgb(255, 196, 206);   // #ffc4ce
$gasum-color-red-light-4: rgb(255, 225, 230);   // #ffe1e6


// *** Colors in "03 visual guidelines.pdf" page 4 "COLORS USED IN GRAPHS, CHARTS AND INFOGRAPHICS"
$gasum-color-yellow-orange: rgb(255, 172, 58);  // #ffac3a;
// $gasum-color-green
// $gasum-color-blue
$gasum-color-purple: rgb(117, 88, 183);         // #7558b7
// $gasum-color-gray
// $gasum-color-brown
$gasum-color-teal: rgb(0, 112, 129);            // #007081


// *** Colors in "03 visual guidelines.pdf" page 2 "BUTTONS"
// Menu:
$gasum-color-menu: $gasum-color-gray-dark;
$gasum-color-menu-dark-1: $gasum-color-gray-dark-1;

// Call to action button:
// $gasum-color-blue                                        button default
$gasum-color-blue-dark-1: rgb(80, 131, 244);    // #5083f4, button hover
$gasum-color-blue-dark-2: rgb(62, 118, 243);    // #3e76f3, button active

// Secondary button
$gasum-color-secondary-button: $gasum-color-gray-light-4; //          secondary button default
$gasum-color-secondary-button-hover: rgb(204, 204, 204);  // #cccccc, secondary button hover
$gasum-color-secondary-button-active: rgb(179, 179, 179); // #b3b3b3, secondary button active

// Expand button
$gasum-color-expand: $gasum-color-gray-light-2;
$gasum-color-expand-hover: rgb(127, 127, 127);  // #7f7f7f
$gasum-color-expand-active: rgb(118, 118, 118); // #767676

// *** Other colors
$gasum-color-blue-dark-3: rgb(75, 109, 184);              // #4b6db8, dark blue in header dividers and notifications box
$gasum-color-app-background: $gasum-color-gray;

$gasum-color-widget-background-1: $gasum-color-white;
$gasum-color-widget-background-2: rgb(251, 251, 251);     // #fbfbfb

$gasum-color-sand: rgb(235, 230, 220);
$gasum-color-yellow: rgb(255, 230, 80);

$gasum-header-height: 135px;
$gasum-sidebar-width: 150px;
$gasum-footer-height: 135px;

$gasum-color-table-border: $gasum-color-brown-light-3;

$grid-gutter-padding: calc($grid-gutter-width / 2);
$padding: $grid-gutter-padding;
$half-padding: calc($grid-gutter-padding / 2);
$font-family: "Verdana Bold", Serif;
$font-size-xlarge: floor(($font-size-base * 3.6));

$section-padding: 40px;
$block-size: 40px;

// Bootstrap-like col helper to remove padding
.col-nopad {
  padding-left: 0;
  padding-right: 0;
}

.col-nopad-left {
  padding-left: 0;
}

.col-nopad-right {
  padding-right: 0;
}

.row-pad {
  padding: $grid-gutter-padding;
}

.row-pad-top {
  padding-top: $grid-gutter-padding;
}

.row-pad-bottom {
  padding-bottom: $grid-gutter-padding;
}

.row-pad-tob {
  padding-top: $grid-gutter-padding;
  padding-bottom: $grid-gutter-padding;
}

// NOTE: The original bootstrap mixin is not found, thus redefine it
@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@import 'animations';
@import 'buttons';
@import 'forms';
@import 'loading-bar';
@import 'flash';
