@mixin gasum-expand {
  background-color: $gasum-color-expand;
  border: none;
  color: $gasum-color-white;
  cursor: pointer;
  height: $block-size;
  outline: none;
  width: $block-size;

  &:hover {
    background-color: $gasum-color-expand-hover;
  }

  &:active {
    background-color: $gasum-color-expand-active;
  }

  &:focus {
    outline: none;
  }
}

.graph-consumption__browse-buttons {
  position: relative;
  top: 0;
}

.btn-secondary {
  color: #fff;
  background-color: $gasum-color-purple;
  border-color: darken($gasum-color-purple, 10);

  &:hover {
    color: #fff;
    background-color: darken($gasum-color-purple, 10);
  }
}

@mixin block-button {
  border: 0;
  padding: 0;
  background-color: $gasum-color-blue;
  color: $gasum-color-white;
  width: $block-size;
  min-height: $block-size;
  line-height: $block-size;

  &--right {
    float: right;
  }
}
